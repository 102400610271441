// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import O from "../../components/Option";
import Persistable from "./Persistable";
import { getText } from "./Enums";
import { getIsoDate } from "../waybills";


export const WaybillTypeEnum = {
    LTL: "LTL",
    FTL: "FTL",
    Rail: "Rail",
    RailLTL: "RailLTL",
    Return: 'Return',
    PlanReturn: 'PlanReturn',
};

export const WaybillTypesWithRoutes = [
  WaybillTypeEnum.FTL, WaybillTypeEnum.Rail
];

export const WaybillTypesEnum = {
    o: "o", //тип заявки основной заявки в заявках с маршрутами
    c: "c", //тип заявки-маршрута
    d: "d" //остальные варианты
};

export const WaybillAvisationStatus = {
    // Не требует авизации
    NONE: 0,
    // Требует авизацию
    REQUESTED: 1,
    // Авизовано
    CONFIRMED: 9,
    // Автоматическая авизация
    AUTO_CONFIRMED: 10,
    // Перемещение
    SORTING: 11
};
//
export const WaybillTypeOptions = [
    new O(WaybillTypeEnum.LTL, "LTL"),
    new O(WaybillTypeEnum.FTL, "FTL"),
    new O(WaybillTypeEnum.Rail, "Rail"),
    new O(WaybillTypeEnum.RailLTL, "RailLTL"),
    new O(WaybillTypeEnum.Return, "Return"),
    new O(WaybillTypeEnum.PlanReturn, "PlanReturn"),
];
WaybillTypeOptions.getText = getText;

export const WaybillTypesOptions = [
    new O(WaybillTypesEnum.c, "c"),
    new O(WaybillTypesEnum.o, "o"),
    new O(WaybillTypesEnum.d, "d"),
];
WaybillTypesOptions.getText = getText;

export const WaybillCarTypeEnum = {
    BOX: 10,
    ISOTERM: 20,
    OPEN: 30,
    LUGGAGE: 40,
    LUGGAGE_INS: 41,
    LUGGAGE_EXPRESS: 42,
    LUGGAGE_EXPRESS_INS: 43,
    CONTHIGH: 100,
    CONTWIDE: 110,
    CONT_REF_20F: 120,
    CONT_REF_40F: 121,
    CONT_INS_20F: 130,
    CONT_INS_40F: 131,
    CONT_20F: 140,
    CONT_40F: 141,
    REF: 150
};

export const WaybillCarTypeOptions = [
    new O(WaybillCarTypeEnum.BOX, "Крытый вагон"),
    new O(WaybillCarTypeEnum.ISOTERM, "Изотермический вагон"),
    new O(WaybillCarTypeEnum.OPEN, "Открытый вагон"),
    new O(WaybillCarTypeEnum.LUGGAGE, "Почтово-багажный вагон"),
    new O(WaybillCarTypeEnum.LUGGAGE_INS, "Почтово-багажный вагон теплый"),
    new O(WaybillCarTypeEnum.LUGGAGE_EXPRESS, "Почтово-багажный вагон скорый"),
    new O(WaybillCarTypeEnum.LUGGAGE_EXPRESS_INS, "Почтово-багажный вагон скорый теплый"),
    new O(WaybillCarTypeEnum.CONTHIGH, "Высокий контейнер"),
    new O(WaybillCarTypeEnum.CONTWIDE, "Широкий контейнер"),
    new O(WaybillCarTypeEnum.CONT_REF_20F, "Реф контейнер 20 футов"),
    new O(WaybillCarTypeEnum.CONT_REF_40F, "Реф контейнер 40 футов"),
    new O(WaybillCarTypeEnum.CONT_INS_20F, "Утепленный контейнер 20 футов"),
    new O(WaybillCarTypeEnum.CONT_INS_40F, "Утепленный контейнер 40 футов"),
    new O(WaybillCarTypeEnum.CONT_20F, "Контейнер 20 футов"),
    new O(WaybillCarTypeEnum.CONT_40F, "Контейнер 40 футов"),
    new O(WaybillCarTypeEnum.REF, "Реф вагон")
];
WaybillCarTypeOptions.getText = getText;

export const BodyCapacityEnum = {
    Capacity90: '90'
};
export const BodyCapacityOptions = [
    new O(BodyCapacityEnum.Capacity90, "90 м3")
];
WaybillCarTypeOptions.getText = getText;

const WaybillStepTypeEnum = {
    PickupDelivery: 0,
    Pickup: 1,
    Haulage: 2,
    Delivery: 4
};

export const WaybillStepTypeOptions = [
    new O(WaybillStepTypeEnum.PickupDelivery, "Pickup+delivery"),
    new O(WaybillStepTypeEnum.Pickup, "Pickup"),
    new O(WaybillStepTypeEnum.Haulage, "Haulage"),
    new O(WaybillStepTypeEnum.Delivery, "Delivery"),
];
WaybillStepTypeOptions.getText = getText;

export class Oc extends O {
    constructor(id, text, color) {
        super(id, text);
        this.color = color;
    }
};

export const WaybillStatusEnum = {
    // Черновик
    DRAFT: 0,
    // Отправлено
    SUBMITTED: 101,
    // Проверка
    ON_APPROVAL: 102,

    // Отмена
    CANCELLED: 995,
    // Отмена FM
    FM_CANCELLED: 996,

    // Удалена ФM
    FM_DELETED: 999,

    // Исполняется
    EXECUTING: 120,
    // Груз доставлен грузополучателю
    CARGO_DELIVERED: 230,
    // Документы отправлены
    DOCS_SENT: 290
};

export const WaybillStatusAnomaly = {

    /// Брак
    REJECT: 240,

    /// Возврат
    REFUND: 241,

    /// Излишек
    OVERAGE: 242,

    /// Недостача
    SHORTAGE: 243,

    /// Пересорт
    REASSORTMENT: 244,

    /// Простой
    DOWNTIME: 245,

    /// Проблема с ТСД
    PROBLEM_TSD: 246,

    /// Без аномалий
    NONE: 247,

    /// Полный возврат
    FULL_REFUND: 248,

    /// Заказ закрыт
    ORDER_CLOSED: 249,

    /// Заказ не авизован
    ORDER_NOT_ADVISED: 250,

    /// Не по заказу ГП
    NOT_RECEIVER_ORDER: 251,

    /// Неверное PCB
    WRONG_RSV: 252,

    /// Проблема с арт.
    PROBLEM_WITH_ART: 253,

    /// Срок годности
    BEST_BEFORE: 254,

    /// Нарушение t режима
    VIOLATION_T_MODE: 255,

    /// Проблема с поддоном
    PALLET_PROBLEM: 256,

    /// Недовложение
    UNDERINVESTMENT: 257,

    /// Утилизация
    DISPOSAL: 258,

    /// Завал паллет
    PALLETS_BLOCKAGE: 259,

    /// Некорректный ШК
    INCORRECT_BAR_CODE: 260,

    /// Другое
    OTHER: 261,

    /// Микс паллет
    PALLET_MIX: 262,
};

export const WaybillStatusAvizationEnum = {
    NONE: 0,
    REQUIRES: 1,
    FILL_DRIVER: 2,
    CONFIRMED: 9,
    AUTO_CONFIRMED: 10,
    SORTING: 11
};


export const AuctionStatusEnum = {
    DRAFT: 0, // Черновик
    CREATED: 10, // Создан
    BIDDING: 20, // Торги
    REQUIRES_DECISION: 30, // Требует решения
    WAIT_FOR_VEHICLE_AND_DRIVER_DETAILS: 40,// Ожидайте данные ТС и водителя
    CANCELLED: 50, // Отмена
    DONE: 60, // Завершён
    FM_CANCELLED: 70, // Отмена FM
};

export const AuctionEditStatuses = [AuctionStatusEnum.DRAFT, AuctionStatusEnum.CREATED, AuctionStatusEnum.CANCELLED];

export const AuctionStatusOptions = [
    new Oc(AuctionStatusEnum.DRAFT, 'Черновик', 'grey'),
    new Oc(AuctionStatusEnum.CREATED, 'Создан'),
    new Oc(AuctionStatusEnum.BIDDING, 'Торги', "yellow"),
    new Oc(AuctionStatusEnum.CANCELLED, 'Отмена', "pink"),
    new Oc(AuctionStatusEnum.FM_CANCELLED, "Отмена FM", "pink"),
    new Oc(AuctionStatusEnum.REQUIRES_DECISION, 'Требует решения', "orange"),
    new Oc(AuctionStatusEnum.WAIT_FOR_VEHICLE_AND_DRIVER_DETAILS, 'Ожидайте данные ТС и водителя', "green"),
    new Oc(AuctionStatusEnum.DONE, 'Завершён', "blue")
];

export const WaybillStatusAnomalyOptionsIsRefund = [
    WaybillStatusAnomaly.REJECT,
    WaybillStatusAnomaly.REFUND,
    WaybillStatusAnomaly.OVERAGE,
    WaybillStatusAnomaly.REASSORTMENT,
    WaybillStatusAnomaly.PROBLEM_TSD,
    WaybillStatusAnomaly.ORDER_CLOSED,
    WaybillStatusAnomaly.ORDER_NOT_ADVISED,
    WaybillStatusAnomaly.NOT_RECEIVER_ORDER,
    WaybillStatusAnomaly.WRONG_RSV,
    WaybillStatusAnomaly.PROBLEM_WITH_ART,
    WaybillStatusAnomaly.BEST_BEFORE,
    WaybillStatusAnomaly.VIOLATION_T_MODE,
    WaybillStatusAnomaly.PALLET_PROBLEM,
    WaybillStatusAnomaly.UNDERINVESTMENT,
    WaybillStatusAnomaly.DISPOSAL,
    WaybillStatusAnomaly.PALLETS_BLOCKAGE,
    WaybillStatusAnomaly.INCORRECT_BAR_CODE,
    WaybillStatusAnomaly.OTHER,
    WaybillStatusAnomaly.PALLET_MIX
];

export const WaybillStatusAnomalyOptions = [
    new Oc(WaybillStatusAnomaly.REJECT, "Брак", "red"),
    new Oc(WaybillStatusAnomaly.REFUND, "Частично принят/Возврат", "yellow"),
    new Oc(WaybillStatusAnomaly.OVERAGE, "Излишек", "orange"),
    new Oc(WaybillStatusAnomaly.SHORTAGE, "Недостача", "pink"),
    new Oc(WaybillStatusAnomaly.REASSORTMENT, "Пересорт", "pink"),
    new Oc(WaybillStatusAnomaly.DOWNTIME, "Простой", "green"),
    new Oc(WaybillStatusAnomaly.PROBLEM_TSD, "Проблема с ТСД", "teal"),
    new Oc(WaybillStatusAnomaly.NONE, "Без аномалий", "blue"),
    new Oc(WaybillStatusAnomaly.FULL_REFUND, "Полный возврат", "blue"),

    new Oc(WaybillStatusAnomaly.ORDER_CLOSED, "Заказ закрыт", "black"),
    new Oc(WaybillStatusAnomaly.ORDER_NOT_ADVISED, "Заказ не авизован", "brown"),
    new Oc(WaybillStatusAnomaly.NOT_RECEIVER_ORDER, "Не по заказу ГП", "violet"),
    new Oc(WaybillStatusAnomaly.WRONG_RSV, "Неверное PCB", "purple"),
    new Oc(WaybillStatusAnomaly.PROBLEM_WITH_ART, "Проблема с арт.", "olive"),
    new Oc(WaybillStatusAnomaly.BEST_BEFORE, "Срок годности", "red"),
    new Oc(WaybillStatusAnomaly.VIOLATION_T_MODE, "Нарушение t режима", "blue"),
    new Oc(WaybillStatusAnomaly.PALLET_PROBLEM, "Проблема с поддоном", "brown"),
    new Oc(WaybillStatusAnomaly.UNDERINVESTMENT, "Недовложение", "blue"),
    new Oc(WaybillStatusAnomaly.DISPOSAL, "Утилизация", "red"),
    new Oc(WaybillStatusAnomaly.PALLETS_BLOCKAGE, "Завал паллет", "teal"),
    new Oc(WaybillStatusAnomaly.INCORRECT_BAR_CODE, "Некорректный ШК", "grey"),
    new Oc(WaybillStatusAnomaly.OTHER, "Другое", "grey"),
    new Oc(WaybillStatusAnomaly.PALLET_MIX, "Микс паллет", "grey"),
];

export const WaybillStatusTsunamiSendingEnum = {
    DEFAULT: 0,
    SENDED: 1,
    ERROR: 2,
};

export const WaybillStatusTsunamiSendingOptions = [
    new Oc(WaybillStatusTsunamiSendingEnum.DEFAULT, "Не требует отправки", "black"),
    new Oc(WaybillStatusTsunamiSendingEnum.SENDED, "Отправлено", "green"),
    new Oc(WaybillStatusTsunamiSendingEnum.ERROR, "Ошибка", "red"),
];

export const WaybillStatusOptions = [
    new Oc(WaybillStatusEnum.DRAFT, "Черновик"),
    new Oc(WaybillStatusEnum.SUBMITTED, "Отправлено", "yellow"),
    new Oc(WaybillStatusEnum.ON_APPROVAL, "Проверка", "orange"),
    new Oc(WaybillStatusEnum.CANCELLED, "Отмена", "pink"),
    new Oc(WaybillStatusEnum.FM_CANCELLED, "Отмена FM", "pink"),
    new Oc(WaybillStatusEnum.FM_DELETED, "Удалена ФМ", "red"),
    new Oc(WaybillStatusEnum.EXECUTING, "Исполняется", "green"),
    new Oc(WaybillStatusEnum.CARGO_DELIVERED, "Груз доставлен", "teal"),
    new Oc(WaybillStatusEnum.DOCS_SENT, "Завершена", "blue"),
];

export const WaybillStatusAvizationOptions = [
    new Oc(WaybillStatusAvizationEnum.NONE, 'Не требует авизации'),
    new Oc(WaybillStatusAvizationEnum.CONFIRMED, 'Авизовано', "green"),
    new Oc(WaybillStatusAvizationEnum.AUTO_CONFIRMED, 'Автоматическая авизация', "green"),
    new Oc(WaybillStatusAvizationEnum.SORTING, 'Перемещение', "green"),
    new Oc(WaybillStatusAvizationEnum.REQUIRES, 'Требует авизации', "red"),
    new Oc(WaybillStatusAvizationEnum.FILL_DRIVER, 'Внести водителя', "orange")
];

export const RouteStatusOptions = [
    new Oc(200, "Планируется", "blue"),
    new Oc(201, "ТС назначено", "blue"),
    new Oc(210, "ТС прибыло на погрузку", "blue"),
    new Oc(212, "ТС убыло с погрузки", "orange"),
    new Oc(220, "Груз принят на кросс-доке", "red"),
    new Oc(221, "Груз вышел с кросс-дока, в транзите", "pink"),
    new Oc(225, "ТС прибыло на выгрузку", "teal"),
    new Oc(226, "ТС убыло с выгрузки", "green")
];

export const LoadCapacityEnum = {
    C900: 900,
    C1500: 1500,
    C2000: 2000,
    C3000: 3000,
    C5000: 5000,
    C10000: 10000,
    C15000: 15000,
    C20000: 20000,
};

export const VehicleCapacityOptions = [
    new O(LoadCapacityEnum.C900, "0,9 т"),
    new O(LoadCapacityEnum.C1500, "1,5 т"),
    new O(LoadCapacityEnum.C2000, "2 т"),
    new O(LoadCapacityEnum.C3000, "3 т"),
    new O(LoadCapacityEnum.C5000, "5 т"),
    new O(LoadCapacityEnum.C10000, "10 т"),
    new O(LoadCapacityEnum.C15000, "15 т"),
    new O(LoadCapacityEnum.C20000, "20 т"),
];

export const nonDeleteErrorStatusSet = [
    WaybillStatusEnum.SUBMITTED,
    WaybillStatusEnum.ON_APPROVAL,
    WaybillStatusEnum.EXECUTING,
    WaybillStatusEnum.CARGO_DELIVERED
];

export const nonDeleteErrorAvisStatusSet = [
    WaybillAvisationStatus.NONE,
    WaybillAvisationStatus.REQUESTED,
];

// take from order
export default class Waybill extends Persistable {

    /** @param {Waybill | undefined} dto */
    constructor(dto = {}) {
        super();

        /** @type {string}  */
        this.id = dto.id || this.getId2();           // internal guid id (or string)

        /** @type {string | undefined}  */
        this.fmid = dto.fmid || "[черновик]";        // is also used as draft name for waybill


        /** @type {string | undefined} */
        this.clientId = dto.clientId;                // company searchName


        /** @type {bool } */
        this.canCopy = dto.canCopy;                // canCopy waybill

        /** @type {string | undefined} */
        this.slotId = dto.slotId;                // slotId

        //this.visitId = dto.visitId;
        this.reservationId = dto.reservationId;
        this.visitNumber = dto.visitNumber;

        const hasPointsUnloading = dto.pointsUnloading && dto.pointsUnloading.length;

        this.deliveryDate = hasPointsUnloading
            ? getIsoDate(dto.pointsUnloading[dto.pointsUnloading.length - 1], "to")
            : null;

        const hasPointsLoading = dto.pointsLoading && dto.pointsLoading.length;

        this.loadingDate = hasPointsLoading
            ? getIsoDate(dto.pointsLoading[0])
            : null;

        /** @type {string | undefined} */
        this.providerId = dto.providerId;                // providerId

        /** @type {string | undefined} */
        this.providerName = dto.providerName;                // providerName

        /** @enum {WaybillTypeEnum} */
        this.shippingType = dto.shippingType || WaybillTypeEnum.LTL;

        /** @type {WaybillStatusEnum} */
        this.status = dto.status || WaybillStatusEnum.DRAFT;

        /** @type {WaybillStatusAnomaly} */
        this.statusAnomaly = dto.statusAnomaly;


        this.statusShipping = dto.statusShipping;

        /** @type {string} */
        this.shippingTemperatureCondition = dto.shippingTemperatureCondition;

        /** @enum {LoadCapacityEnum} */
        this.vehicleCapacity = dto.vehicleCapacity;

        /** @enum {WaybillCarTypeEnum} */
        this.carType = dto.carType;

        /** @type {string} */
        this.cargoType = dto.cargoType;

        /** @type {number | undefined} */
        this.cargoCost = dto.cargoCost;


        /** @type {WaybillPoint[] | undefined} */
        this.pointsLoading = dto.pointsLoading || [];

        /** @type {WaybillPoint[] | undefined} */
        this.pointsUnloading = dto.pointsUnloading || [];

        this.steps = dto.steps;

        /** @type {boolean} */
        this.isValid = dto.isValid;

        /** @type {boolean} */
        this.isPooling = dto.isPooling;

        /** @type {boolean | undefined} */
        this.hubLoading = dto.hubLoading;

        /** @type {boolean | undefined} */
        this.pickupType = dto.pickupType;

        /** @type {boolean | undefined} */
        this.bodyCapacity = dto.bodyCapacity;

        /** @type {string | undefined} */
        this.pickupId = dto.pickupId;

        /** @enum {WaybillAvisationStatus} */
        this.avisationStatus = dto.avisationStatus;

        /** @type {boolean | undefined} */
        this.canHaveAvisation = dto.canHaveAvisation;

        /** @type {string | undefined} */
        this.podIdAvailable = dto.podIdAvailable;

        this.temperatureCondition = dto.temperatureCondition;

        this.returnWaybillId = dto.returnWaybillId;

        this.returnWaybillFMID = dto.returnWaybillFMID;
        this.tripNumber = dto.tripNumber;
        this.isLocked = dto.isLocked;
        this.isCopy = dto.isCopy;
        this.auction = dto.auction;
        this.canUseCarType = dto.canUseCarType;
        this.carTypeFMKey = dto.carTypeFMKey;
        this.carTypeName = dto.carTypeName;

        this.parentPortalId = dto.parentPortalId;
        this.waybillType = dto.waybillType;
        this.warehouseOrderNo = dto.warehouseOrderNo;
        this.unitsCount = dto.unitsCount;
        this.unitsType = dto.unitsType;
        this.pointLoadingAddressSettlement = dto.pointLoadingAddressSettlement;
        this.pointLoadingAddress = dto.pointLoadingAddress;
        this.pointUnloadingAddressSettlement = dto.pointUnloadingAddressSettlement;
        this.pointUnloadingAddress = dto.pointUnloadingAddress;
        this.loadingDatePlan = dto.loadingDatePlan;
        this.loadingTimePlanFrom = dto.loadingTimePlanFrom;
        this.loadingTimePlanTo = dto.loadingTimePlanTo;
        this.loadingDateFact = dto.loadingDateFact;
        this.loadingTimeFact = dto.loadingTimeFact;
        this.dispatchDateFact = dto.dispatchDateFact;
        this.dispatchTimeFact = dto.dispatchTimeFact;
        this.unloadingDatePlan = dto.unloadingDatePlan;
        this.unloadingTimePlanFrom = dto.unloadingTimePlanFrom;
        this.unloadingTimePlanTo = dto.unloadingTimePlanTo;
        this.unloadingDateFact = dto.unloadingDateFact;
        this.unloadingTimeFact = dto.unloadingTimeFact;
        this.unloadingLeaveDateFact = dto.unloadingLeaveDateFact;
        this.unloadingLeaveTimeFact = dto.unloadingLeaveTimeFact;
        this.isArchive = dto.isArchive;
        this.driverName = dto.driverName;
        this.truckLicensePlate = dto.truckLicensePlate;
        this.isDraftRoute = dto.isDraftRoute;
    }

    isValidated() {
        return false;
    }
}
